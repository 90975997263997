import styled, { themeGet, device } from "@theme/utils";
export const PartnerWrap = styled.div`
    padding: 0 0 60px 0;
    ${device.small} {
        padding: 0 0 80px 0;
    }
    ${device.medium} {
        padding: 0 0 100px 0;
    }
    ${device.large} {
        padding: 0 0 120px 0;
    }
`;
export const TrustedPartnersBox = styled.div`
    padding: 80px 100px;
    background-color: #f4eaff;
    border-radius: 15px;
`;
export const SectionTitleThree = styled.div`
    margin-bottom: 30px;
    text-align: center;
`;
export const Title = styled.h2``;
